import React from "react"

import classes from "./PricingBlock.module.scss"

const createPricingRows = (prices, modal) => {
  return prices.map((value, id) => (
    <div
      key={id}
      className={classes.priceTag}
      onClick={modal}
      onKeyDown={modal}
      role="button"
      tabIndex={0}
    >
      <h3 className={classes.title}>{value.nazwa_ceny}</h3>
      <p className={classes.price}>{value.cena}</p>
    </div>
  ))
}

const createCategoryPricingRows = (prices, modal) => {
  return prices.map((value, id) => (
    <div
      key={id}
      className={classes.priceTag}
      onClick={modal}
      onKeyDown={modal}
      role="button"
      tabIndex={0}
    >
      <h3 className={classes.category}>{value.nazwa_kategorii}</h3>
      {value.cena.length > 0 && <div className={`${classes.pricesList} ${classes.categoryPriceList}`}>{createPricingRows(value.cena, modal)}</div>}
    </div>
  ))
}

// const nonCategoryPrices = prices??
// const categoryPrices = 

const PricingBlock = ({ name, prices, category_prices, modal }) => {
  return (
    <section id={name} className={classes.pricing_block}>
      <h2 className={classes.treatment_name} onClick={modal} onKeyDown={modal}>
        {name}
      </h2>
      {category_prices.length > 0 && <div className={classes.categoryPriceList} >{createCategoryPricingRows(category_prices, modal)}</div>}
      {prices.length > 0 && <div className={classes.pricesList}>{createPricingRows(prices, modal)}</div>}
    </section>
  )
}

export default PricingBlock
