import React, { useState } from "react"

import classes from "./Pricing.module.scss"
import PricingBlock from "./PricingBlock/PricingBlock"
import { showModal } from "../Treatments/treatments"

const createPricingBlocks = (prices, setModal) => {
  return prices
    .sort((a, b) => a.node.pozycja_na_stronie - b.node.pozycja_na_stronie)
    .map((value, id) => (
      <PricingBlock
        key={id}
        name={value.node.Nazwa}
        prices={value.node.konkretna_cena}
        category_prices={value.node.cena_z_kategoria}
        modal={() =>
          showModal(
            value.node.Nazwa,
            value.node.Zdjecie,
            value.node.Cena,
            value.node.Czas,
            value.node.Opis,
            setModal
          )
        }
      />
    ))
}

const Pricing = data => {
  const [modal, setModal] = useState()
  return (
    <section id={classes.pricing}>
      <h1 className={classes.title}>CENNIK</h1>
      <div className={classes.prices}>
        {createPricingBlocks(data.data, setModal)}
      </div>
      <div className={classes.modal}>{modal ?? null}</div>
    </section>
  )
}

export default Pricing
