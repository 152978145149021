import React, { useState } from "react"
import Img from "gatsby-image"

import classes from "./Treatments.module.scss"
import TreatmentBlock from "./TreatmentBlock/TreatmentBlock"
import TreatmentModal from "./TreatmentModal/TreatmentModal"

const closeModal = setModal => {
  setModal(null)
}

const createTreatments = (treatments, setModal) => {
  return treatments
    .sort((a, b) => a.node.pozycja_na_stronie - b.node.pozycja_na_stronie)
    .map((value, id) => (
      <TreatmentBlock
        key={id}
        onClick={() =>
          showModal(
            value.node.Nazwa,
            value.node.Zdjecie,
            value.node.Cena,
            value.node.Czas,
            value.node.Opis,
            setModal
          )
        }
        name={value.node.Nazwa}
        img={value.node.Zdjecie}
        price={value.node.Cena}
        time={value.node.Czas}
      />
    ))
}

const showModal = (name, img, price, time, desc, setModal) => {
  const newModal = (
    <TreatmentModal
      img={img}
      desc={desc}
      name={name}
      price={price}
      time={time}
      close={() => closeModal(setModal)}
    />
  )
  setModal(newModal)
}

const Treatments = ({ title_photo, treatments }) => {
  const [modal, setModal] = useState()
  return (
    <div className={classes.container}>
      <section id={classes.title}>
        <Img
          className={classes.title_photo}
          fluid={title_photo.childImageSharp.fluid}
          alt={"zabiegi"}
        />
        <div className={classes.text}>
          <h1 className={classes.title_text}>Zabiegi</h1>
          <h3 className={classes.subtitle_text}>
            Połączenie wiedzy, doświadczenia i technologii
          </h3>
        </div>
      </section>
      <section id={classes.treatments}>
        {createTreatments(treatments, setModal)}
      </section>
      <section id={classes.modal}>{modal ?? null}</section>
    </div>
  )
}

export default Treatments
export { showModal }
