import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"

import Pricing from "../components/Pricing/Pricing"
import SEO from "../components/seo"

function Cennik({ data }) {
  return (
    <Layout>
      <SEO
        title="Cennik"
        description={data.tags.opis_strony}
        keywords={data.tags.tagi}
      />
      <Pricing data={data.pricing.edges} />
    </Layout>
  )
}

export default Cennik

export const query = graphql`
  query pricing {
    tags: strapiMetaTag(nazwa_strony: { eq: "Cennik" }) {
      opis_strony
      tagi
    }
    pricing: allStrapiZabieg {
      edges {
        node {
          Nazwa
          Cena
          Opis
          pozycja_na_stronie
          konkretna_cena {
            cena
            nazwa_ceny
          }
          cena_z_kategoria {
            nazwa_kategorii
            cena {
              cena
              nazwa_ceny
            }
          }
          Zdjecie {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
